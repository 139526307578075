import room01 from "./rooms/room_01.jpg";
import room02 from "./rooms/room_02.jpg";
import room03 from "./rooms/room_03.jpg";

const RoomsData = [
  { 
    id: 1, 
    name: "Privé en Bas", 
    type: "Privés en Bas", 
    price: 1, 
    event: "Grand Bataille 1024", 
    checkinDate: "Aug 11", 
    checkoutDate: "Aug 18", 
    quantity: 4, 
    imageUrl: room01 
  },
  { 
    id: 2, 
    name: "Privé en Haute", 
    type: "Privés en Haute", 
    price: 1950, 
    event: "Grand Bataille 1024", 
    checkinDate: "Aug 11", 
    checkoutDate: "Aug 18", 
    quantity: 3, 
    imageUrl: room02 
  },
  { 
    id: 3, 
    name: "Grenier Pauvre", 
    type: "Grenier Pauvre", 
    price: 850, 
    event: "Grand Bataille 1024", 
    checkinDate: "Aug 11", 
    checkoutDate: "Aug 18", 
    quantity: 6, 
    imageUrl: room03 
  },
];

export default RoomsData;
