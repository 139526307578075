import React from 'react';
import { Box, Grid, Text, Image } from '@chakra-ui/react';
import { useLanguage } from './LanguageContext'; // Adjust the import path as necessary
import cousinsData from './cousins.json';
import logo from '../images/logo.png';

const GuildSection = () => {
  const { language } = useLanguage(); // Accessing the current language state

  // Translations
  const translations = {
    EN: {
      guildDecoration: "Guild Decoration",
      guildDescription: "This section is dedicated to describing the guild. Here, you can add information about the guild's history, mission, and any other relevant details you want visitors to know.",
      guildMembers: "Guild Members",
      firstEvent: "First Event:",
      lastEvent: "Last Event:",
      totalEvents: "Total Events:",
    },
    FR: {
      guildDecoration: "Décoration de la Guilde",
      guildDescription: "Cette section est dédiée à la description de la guilde. Ici, vous pouvez ajouter des informations sur l'histoire, la mission de la guilde et tout autre détail pertinent que vous souhaitez que les visiteurs connaissent.",
      guildMembers: "Membres de la Guilde",
      firstEvent: "Premier:",
      lastEvent: "Dernier:",
      totalEvents: "Événements Total:",
    },
  };

  const t = translations[language]; // Current translations based on the language

  return (
    <Box>
      {/* Customizable Box for decoration */}
      <Box height="300px" bg="gray.200" display="flex" alignItems="center" justifyContent="center">
        <Text fontSize="2xl" color="gray.600">{t.guildDecoration}</Text>
      </Box>

      {/* Guild Description */}
      <Text fontSize="lg" mt={4} mb={6} p={4}>{t.guildDescription}</Text>

      {/* Cards Section Header */}
      <Box bg="#2e2d78" p={4} display="flex" alignItems="center">
        <Image src={logo} boxSize="50px" mr={2} />
        <Text fontSize="2xl" color="white" fontWeight="bold">{t.guildMembers}</Text>
      </Box>

      {/* Grid of guild members */}
      <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6} mt={6}>
        {cousinsData.map((cousin, index) => (
          <Box key={index} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
            <Text fontSize="xl" fontWeight="bold">{cousin.name}</Text>
            <Text><strong>{t.firstEvent}</strong> {cousin.firstEvent}</Text>
            <Text><strong>{t.lastEvent}</strong> {cousin.lastEvent}</Text>
            <Text><strong>{t.totalEvents}</strong> {cousin.totalEvents}</Text>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default GuildSection;
