import React, { useState } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import photos from './photosData'; // Ensure this import path matches your file structure

function PhotoGallery() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelectImage = (index) => {
    setCurrentIndex(index);
  };

  const currentPhoto = photos[currentIndex];

  return (
    <Flex direction="column" align="center" w="100%">
      {/* Full-Width Thumbnails Bar with smaller thumbnails for quick loading */}
      <Flex
        wrap="wrap"
        justify="center"
        w="120%"
        bg="#2e2d78"
        p={2}
        position="sticky"
        top={0}
        zIndex={1}
        mt={-5}
      >
        {photos.map((photo, index) => (
          <Box
            key={index}
            p={1}
            onClick={() => handleSelectImage(index)}
            cursor="pointer"
            opacity={currentIndex === index ? 1 : 0.6}
            transition="opacity 0.3s ease-in-out"
          >
            <Image src={photo.thumb} alt={`Thumbnail of ${photo.name}`} boxSize="100px" objectFit="cover" />
          </Box>
        ))}
      </Flex>

      {/* Image Display with Navigation Buttons, including adjustments for the thumbnails */}
      <Flex justify="center" align="center" width="70vw" position="relative" my={4}>
        {/* Previous Button */}
        <Button
          colorScheme="blue"
          variant="ghost"
          position="absolute"
          left="-50px"
          size="lg"
          onClick={() => handleSelectImage((currentIndex - 1 + photos.length) % photos.length)}
        >
          ◀
        </Button>

        {/* Image and Details Frame */}
        <Box border="2px" borderColor="gray.200" p={4} rounded="md" boxShadow="md" bg="white" maxWidth="70%">
          <Image src={currentPhoto.src} alt={currentPhoto.name} maxWidth="100%" maxHeight="60vh" objectFit="contain" />
          <Box p={4}>
            <Text fontSize="xl" fontWeight="bold" mt={2}>{currentPhoto.name}</Text>
            {currentPhoto.cousins.length > 0 && (
              <Text fontSize="md">
                <strong>Cousins:</strong> {currentPhoto.cousins.join(', ')}
              </Text>
            )}
            <Text fontSize="md"><strong>Date:</strong> {currentPhoto.date}</Text>
            <Text fontSize="md"><strong>Event:</strong> {currentPhoto.event}</Text>
          </Box>
        </Box>

        {/* Next Button */}
        <Button
          colorScheme="blue"
          variant="ghost"
          position="absolute"
          right="-50px"
          size="lg"
          onClick={() => handleSelectImage((currentIndex + 1) % photos.length)}
        >
          ▶
        </Button>
      </Flex>
    </Flex>
  );
}

export default PhotoGallery;
