// Import both the full-size images and thumbnails
import photo0 from './photos/photo_000.jpg';
import thumb0 from './photos/thumbs/thumb_000.jpg';
import photo1 from './photos/photo_001.jpg';
import thumb1 from './photos/thumbs/thumb_001.jpg';
import photo2 from './photos/photo_002.jpg';
import thumb2 from './photos/thumbs/thumb_002.jpg';

const photos = [
  {
    src: photo0,
    thumb: thumb0,
    name: "Camp Désordonné",
    cousins: [],
    date: "2023-08-16",
    event: "Grand Bataille 1023"
  },
  {
    src: photo1,
    thumb: thumb1,
    name: "Tente Vieille",
    cousins: [],
    date: "2023-08-16",
    event: "Grand Bataille 1023"
  },
  {
    src: photo2,
    thumb: thumb2,
    name: "Tente Seconde",
    cousins: [],
    date: "2023-08-16",
    event: "Grand Bataille 1023"
  },
  
];

export default photos;
