import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import logo from '../images/logo.png';

const Intro = ({ onLiftUp }) => {
  return (
    <Flex
      height="100vh"
      width="100%"
      bg="#2e2d78"
      justify="center"
      align="center"
      onClick={onLiftUp}
      cursor="pointer"
      transition="all 0.5s ease-in-out"
    >
      <Image src={logo} alt="Minerval Logo" width="40%" />
    </Flex>
  );
};

export default Intro;
