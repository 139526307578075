import React from 'react';
import { Box, Text, Image, Button, VStack } from '@chakra-ui/react';

function RoomCard({ room, onReserve }) {
  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
      <VStack spacing={4} align="stretch">
        <Image src={room.imageUrl} alt={`Image of ${room.name}`} boxSize="100%" objectFit="cover" />
        <Box>
          <Text fontWeight="bold">{room.name}</Text>
          <Text fontSize="sm">{room.description}</Text>
          <Text fontSize="sm">Price: ${room.price}</Text>
          <Text fontSize="sm">Available: {room.inventory}</Text>
        </Box>
        <Button colorScheme="blue" onClick={() => onReserve(room)}>Reserve</Button>
      </VStack>
    </Box>
  );
}

export default RoomCard;
