import React from 'react';
import { Box, Image, Text, VStack, Flex } from '@chakra-ui/react';

function HistoryCard({ title, text, image, attendents }) {
  return (
    <Box maxW="700px" borderWidth="1px" borderRadius="lg" overflow="hidden" m="4" p="4" boxShadow="lg" borderColor="#2e2d78" bg="white">
      <VStack spacing={4} align="stretch">
        <Text fontSize="2xl" fontWeight="bold" color="#2e2d78" textAlign="center">{title}</Text>
        <Image
          src={image}
          alt={`Image for ${title}`}
          borderRadius="md"
          objectFit="cover"
        />
        <Text>{text}</Text>
        {attendents && attendents.length > 0 && (
          <Flex wrap="wrap" justify="center" gap={2}>
            {attendents.map((cousin, index) => (
              <Box key={index} p={2} bg="#2e2d78" color="white" borderRadius="md">
                <Text fontSize="md">{cousin}</Text>
              </Box>
            ))}
          </Flex>
        )}
      </VStack>
    </Box>
  );
}

export default HistoryCard;
