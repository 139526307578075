import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react';
import { LanguageProvider } from './components/LanguageContext';
import { PayPalScriptProvider } from "@paypal/react-paypal-js"; // Import PayPalScriptProvider
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <LanguageProvider>
        <PayPalScriptProvider options={{ "client-id": "AQ-Ti4UO29Ar_58YxY5PrEYd27q8Ncc9fwqry35Vm54WTC8VvgScw7yMkoK5f4bzEtGENW2-Utd1t7Xt" }}> 
          <App />
        </PayPalScriptProvider>
      </LanguageProvider>
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();
