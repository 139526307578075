import React, { useState } from 'react';
import { Box, Container, Heading, SimpleGrid, Text, useDisclosure } from '@chakra-ui/react';
import RoomCard from './RoomCard';
import ReservationModal from './ReservationModal';
import RoomsData from './RoomsData.js';
import { useLanguage } from './LanguageContext';

function Inscription() {
  const [rooms, setRooms] = useState(RoomsData);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRoom, setSelectedRoom] = useState(null);
  const { language } = useLanguage();

  const handleReserveClick = (room) => {
    setSelectedRoom(room);
    onOpen();
  };

  const handleCompleteReservation = (roomId) => {
    const updatedRooms = rooms.map(room =>
      room.id === roomId ? { ...room, inventory: room.inventory - 1 } : room
    );
    setRooms(updatedRooms);
    onClose();
  };

  return (
    <Box>
      <Container maxW="50%" centerContent py="5">
        <Heading mb="2">{language === 'EN' ? 'Grand Battle 1024' : 'Grande Bataille 1024'}</Heading>
        <Text mb="4" maxW="xl" textAlign="center"><strong>11 - 18 August 2024</strong></Text>
        <Text mb="4" maxW="xl" textAlign="center">
          {language === 'EN' ?
            'Experience a week-long adventure immersing you in the world of Bicolline, embarking on a journey of your own making.' :
            'Participez à une semaine immersive dans l’univers de Bicolline, où votre aventure vous appartient.'}
        </Text>
      </Container>

      <Container maxW="80%" centerContent>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="6">
          {rooms.map(room => (
            <RoomCard key={room.id} room={room} onReserve={() => handleReserveClick(room)} />
          ))}
        </SimpleGrid>
      </Container>

      {selectedRoom && (
        <ReservationModal
          isOpen={isOpen}
          onClose={onClose}
          room={selectedRoom}
          onComplete={handleCompleteReservation}
        />
      )}

      <Container maxW="50%" centerContent>
        <Text mb="4" maxW="80%" textAlign="center" py="10">
          {language === 'EN' ?
            'The following rooms are available for rent at our guild cabin. Please review the guild rules before booking with us for the event.' :
            'Les chambres suivantes sont disponibles à la location dans notre cabane de guilde. Veuillez consulter les règles de la guilde avant de réserver une place avec nous pour l\'événement.'}
        </Text>
      </Container>
    </Box>
  );
}

export default Inscription;
