/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Text, Image, Link, Flex, Box, Checkbox, Select, Input, VStack, HStack } from '@chakra-ui/react';
import { useLanguage } from './LanguageContext';
import cousinsList from './cousins.json';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function ReservationModal({ isOpen, onClose, room, onComplete }) {
  const [isCousin, setIsCousin] = useState(false);
  const [selectedCousin, setSelectedCousin] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const modalContentRef = useRef(null);
  const { language } = useLanguage();

  useEffect(() => {
    if (modalContentRef.current) {
      modalContentRef.current.style.transition = 'height 0.3s ease';
      modalContentRef.current.style.height = 'auto';
    }
  }, [isOpen]);

  const t = {
    EN: {
      cousinQuestion: "Are you a Cousin de Minerval?",
      whichCousin: "Which cousin?",
      enterYourName: "Enter your name",
      yourEmailAddress: "Your email address",
      checkIn: "Arrive:",
      checkOut: "Depart:",
      price: "Price:",
      directions: "Directions 🗺",
      packingList: "Packing List 🎒",
      guildRules: "Guild Rules ⚖",
      frequentQuestions: "Frequent Questions 🤔",
      confirmReservation: "Confirm Reservation",
      cancel: "Cancel",
    },
    FR: {
      cousinQuestion: "Êtes-vous un Cousin de Minerval?",
      whichCousin: "Quelle Cousin?",
      enterYourName: "Entrez votre nom",
      yourEmailAddress: "Votre adresse e-mail",
      checkIn: "Arrivée:",
      checkOut: "Départ:",
      price: "Prix:",
      directions: "Itinéraire 🗺",
      packingList: "Liste de Packing 🎒",
      guildRules: "Guilde Règles ⚖",
      frequentQuestions: "Questions Fréquentes 🤔",
      confirmReservation: "Confirmer la Réservation",
      cancel: "Annuler",
    }
  }[language];

  const handleCousinSelect = (event) => {
    const selectedCousinName = event.target.value;
    setSelectedCousin(selectedCousinName);
    const cousin = cousinsList.find(c => c.name === selectedCousinName);
    if (cousin) {
      setEmail(cousin.email);
      setName(cousin.name);
    }
  };

  const handlePayPalApprove = (data, actions) => {
    return actions.order.capture().then(details => {
      fetch('/.netlify/functions/manageInventory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'update', roomId: room.id }),
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        onComplete(room.id);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    });
  };

  const isFormComplete = name !== '' && email !== '';

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="2xl">
      <ModalOverlay />
      <ModalContent maxW="container.md" ref={modalContentRef}>
        <ModalBody>
          <Flex direction={{ base: "column", md: "row" }} align="start">
            <VStack spacing={4} align="start">
              <Text fontSize="2xl" mb={1}><strong>{room.name}</strong></Text>
              <Image src={room.imageUrl} alt={`Image of ${room.name}`} borderRadius="md" boxSize="200px" objectFit="cover" />
            </VStack>
            <Box flex="1" ml={{ base: 0, md: 6 }} mt={{ base: 4, md: 0 }} w="full">
              <Text fontSize="xl" mb={1}><strong>{room.event}</strong></Text>
              <Checkbox isChecked={isCousin} onChange={(e) => setIsCousin(e.target.checked)} mb={2}>
                {t.cousinQuestion}
              </Checkbox>
              {isCousin ? (
                <Select placeholder={t.whichCousin} mb={4} onChange={handleCousinSelect}>
                  {cousinsList.map((cousin, index) => (
                    <option key={index} value={cousin.name}>{cousin.name}</option>
                  ))}
                </Select>
              ) : (
                <Input placeholder={t.enterYourName} mb={4} value={name} onChange={(e) => setName(e.target.value)} />
              )}
              <Input placeholder={t.yourEmailAddress} mb={4} value={email} onChange={(e) => setEmail(e.target.value)} />
              <HStack justify="space-between" spacing={4} w="full">
                <Box>
                  <Text fontSize="md" mb={1}><strong>{t.checkIn}</strong> {room.checkinDate}</Text>
                  <Text fontSize="md" mb={1}><strong>{t.checkOut}</strong> {room.checkoutDate}</Text>
                  <Text fontSize="xl" mb={1}><strong>{t.price}</strong> ${room.price} total</Text>
                </Box>
                <VStack align="end" spacing={0}>
                  <Link fontSize="md" color="2e2d78" href="#" _hover={{ textDecoration: 'none' }}><strong>{t.directions}</strong></Link>
                  <Link fontSize="md" color="2e2d78" href="#" _hover={{ textDecoration: 'none' }}><strong>{t.packingList}</strong></Link>
                  <Link fontSize="md" color="2e2d78" href="#" _hover={{ textDecoration: 'none' }}><strong>{t.guildRules}</strong></Link>
                  <Link fontSize="md" color="2e2d78" href="#" _hover={{ textDecoration: 'none' }}><strong>{t.frequentQuestions}</strong></Link>
                </VStack>
              </HStack>
            </Box>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <Box w="full" cursor={isFormComplete ? 'pointer' : 'not-allowed'}>
              <PayPalButtons 
                style={{ layout: 'vertical', height: 45, shape: 'rect', color: 'blue' }}
                createOrder={(data, actions) => {
                  if (!isFormComplete) {
                    return false;
                  }
                  return actions.order.create({
                    purchase_units: [{
                      amount: {
                        value: room.price.toString(),
                      },
                    }],
                  });
                }}
                onApprove={handlePayPalApprove}
                onClick={() => {
                  if (modalContentRef.current) {
                    modalContentRef.current.style.height = 'auto';
                  }
                }}
                onCancel={() => {
                  if (modalContentRef.current) {
                    modalContentRef.current.style.height = 'auto';
                  }
                }}
                disabled={!isFormComplete}
              />
            </Box>
          </PayPalScriptProvider>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ReservationModal;
