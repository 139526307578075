/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import { ChakraProvider, useBreakpointValue, Flex, Box, Text, IconButton, useDisclosure, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import './App.css';
import { useLanguage } from './components/LanguageContext';

import Intro from './components/Intro';
import PhotoGallery from './components/PhotoGallery';
import HistorySection from './components/HistorySection';
import Inscription from './components/Inscription';
import LanguageToggle from './components/LanguageToggle';
import GuildSection from './components/GuildSection';


function App() {
  const { isOpen, onToggle } = useDisclosure();
  const [currentOption, setCurrentOption] = useState('home');
  const [introVisible, setIntroVisible] = useState(true);
  const { language } = useLanguage();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const translate = (key) => ({
    EN: {
      home: "Guild",
      histoire: "History",
      photos: "Photos",
      inscription: "Registration",
      homeContent: "Home"
    },
    FR: {
      home: "Guilde",
      histoire: "Histoire",
      photos: "Photos",
      inscription: "Inscription",
      homeContent: "Accueil"
    }
  }[language][key]);

  const liftUpIntro = () => setIntroVisible(false);

  const handleMenuItemClick = (option) => {
    setCurrentOption(option);
    if (isMobile) onToggle(false);
  };

  return (
    <ChakraProvider>
      {introVisible ? <Intro onLiftUp={liftUpIntro} /> : (
        <>
          <Box bg="#2e2d78" w="100%" p="4" display="flex" justifyContent="space-between" alignItems="center" px="10%">
            {isMobile ? (
              <Menu>
                <MenuButton as={IconButton} icon={<HamburgerIcon />} variant="outline" aria-label="Options" color="white" />
                <MenuList>
                  {['home', 'histoire', 'photos', 'inscription'].map(key => (
                    <MenuItem key={key} onClick={() => handleMenuItemClick(key)}>{translate(key)}</MenuItem>
                  ))}
                </MenuList>
              </Menu>
            ) : (
              <>
                {['home', 'histoire', 'photos', 'inscription'].map(key => (
                  <Text key={key} className="header-text" color="white" cursor="pointer" onClick={() => handleMenuItemClick(key)}>
                    {translate(key)}
                  </Text>
                ))}
              </>
            )}
        {isMobile && <Text color="white" fontSize="lg">{translate(currentOption)}</Text>}
            {isMobile ? null : <LanguageToggle />}
          </Box>
          <Flex direction="column" flex="1" p="4">
            {currentOption === 'photos' && <PhotoGallery />}
            {currentOption === 'inscription' && <Inscription />}
            {currentOption === 'histoire' && <HistorySection />}
            {currentOption === 'home' && <GuildSection />}
          </Flex>
        </>
      )}
    </ChakraProvider>
  );
}


export default App;
