// In LanguageToggle.js or wherever the LanguageToggle component is defined

import React from 'react';
import { Button, Box } from '@chakra-ui/react';
import { useLanguage } from './LanguageContext'; // Adjust import path as necessary

function LanguageToggle() {
  const { language, setLanguage } = useLanguage();

  const toggleLanguage = () => {
    setLanguage(language === 'EN' ? 'FR' : 'EN');
  };

  return (
    <Box position="fixed" bottom="0" right="0" m={4} zIndex="tooltip">
      <Button onClick={toggleLanguage} variant="ghost" aria-label="Toggle Language">
        {language === 'EN' ? '🍔' : '🥐'}
      </Button>
    </Box>
  );
}

export default LanguageToggle;
