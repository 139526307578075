import React from 'react';
import { Flex, Text, Box } from '@chakra-ui/react';
import HistoryCard from './HistoryCard';

// Photo Import
import GB2023 from "./historypic/GB2023.jpg";
import GB2022 from "./historypic/GB2022.jpg";

// Stories of Minerval
const stories = [
    { date: "2023-08-16", title: "Grande Bataille 1023", text: "This is the text for story 1.", image: GB2023, attendents: ["Tonoune", "Riel", "Ivier", "X", "Bert", "Bard", "Lapin", "Amandine", "Erick", "Vagabond (et l'elf)"] },
    { date: "2022-08-11", title: "Grande Bataille 1022", text: "This is the text for story 2.", image: GB2022, attendents: ["Bard", "Ivier", "Bert", "Tonoune", "Amandine", "Vagabond"]},
    // Add more stories as needed
];

const sortedStories = stories.sort((a, b) => new Date(b.date) - new Date(a.date));

function HistorySection() {
  return (
    <Flex direction="column" align="center" w="full">
      <Box bg="#2e2d78" w="full" p="4">
        <Text fontSize="2xl" fontWeight="bold" color="white" textAlign="center">History of Minerval</Text>
      </Box>
      {sortedStories.map((story, index) => (
        <HistoryCard
          key={index}
          title={story.title}
          text={story.text}
          image={story.image}
          attendents={story.attendents}
        />
      ))}
    </Flex>
  );
}

export default HistorySection;
